<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      :fill="color"
      fill-rule="nonzero"
      d="M.75 3.25h16.317a.75.75 0 1 0 0-1.5H.75a.75.75 0 0 0 0 1.5z"
    />
    <circle cx="4.683" cy="2.5" r="2.5" :fill="color" />
    <circle cx="4.683" cy="2.5" r="1.5" fill="#FFF" />
    <path
      :fill="color"
      fill-rule="nonzero"
      d="M.75 17.25h16.317a.75.75 0 1 0 0-1.5H.75a.75.75 0 1 0 0 1.5z"
    />
    <circle cx="7.683" cy="16.5" r="2.5" :fill="color" />
    <circle cx="7.683" cy="16.5" r="1.5" fill="#FFF" />
    <path
      :fill="color"
      fill-rule="nonzero"
      d="M.75 10.25h16.317a.75.75 0 1 0 0-1.5H.75a.75.75 0 0 0 0 1.5z"
    />
    <circle cx="12.683" cy="9.5" r="2.5" :fill="color" />
    <circle cx="12.683" cy="9.5" r="1.5" fill="#FFF" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
